import React from 'react';
import { Button, Card, Divider, Icon, Image, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { GiBreakingChain } from "react-icons/gi";
import { PiUsersThreeFill } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';



const ChainCard = ({ chain }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCardClick = () => {
        console.log("handleCardClick", chain);
        navigate(`/chain/${chain.referenceId}`);
    };
    return (
        <Card
            onClick={handleCardClick}
            align='center'
            className='flex h-1/6 w-11/12 mt-3'
            variant='elevated'
            boxShadow="md"
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
        >
            <div className='flex flex-row-reverse  w-full h-full justify-start p-1' >
                <Image
                    src='https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw3f3e1c6f/productimages/singlepackshot/562731C00_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F5F5F5'
                    height={'100%'}
                />
                <div className='w-full'>
                    <div className='flex h-1/2 w-full justify-center'>
                        <Text fontWeight={500} fontSize={'xx-large'} color="teal.800"> {chain?.name} </Text>
                        {chain?.status == 'expired' && <div className='flex absolute left-0 m-1'>
                            <Icon style={{ right: 0 }} right={0} className='flex' color={'red'} as={GiBreakingChain} boxSize={6} />

                        </div>}
                    </div>
                    <Divider />
                    <div className='flex h-1/2  justify-around items-end p-2'>
                        <Text fontSize={'x-large'} color="teal.800"> {chain?.ageInDays}D </Text>
                        <div className='flex justify-center items-center'>
                            <Icon as={PiUsersThreeFill} color="teal.800" boxSize={6} marginRight={2} />
                            <Text fontSize={'x-large'} color="teal.800"> {chain?.uniqueOwnersCount} </Text>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ChainCard;