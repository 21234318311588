import React, { useEffect, useState } from 'react';
import { Avatar, Spinner, Button, Card, CardBody, CardFooter, CardHeader, Divider, Grid, Heading, Icon, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { finishOnboardingChain, getChains } from '../../Slicers/chainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ChainCard from '../ChainCard';
import LoadingComponent from '../../Loading';
import { useNavigate } from 'react-router-dom';
import { getProfileAsync } from '../../Slicers/profileSlice';

const ListOfChains = ({ }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // onboarding chain
    let chains = useSelector((state) => state.chain.listOfChains);
    let lang = useSelector((state) => state.auth.lang);
    let loading = useSelector((state) => state.chain.loading);
    let onboardingChainId = useSelector((state) => state.chain.onboardingChainId);
    let name = useSelector((state) => state.profile.profile.name);

    useEffect(() => {
        console.log('chains', chains);
        // if (!chains || chains?.length === 0)
            dispatch(getChains({}));
            dispatch(getProfileAsync({}));
    }, []);

    useEffect(() => {
        if (onboardingChainId) {
            dispatch(finishOnboardingChain());
            navigate(`/chain/${onboardingChainId}`);
        }
    }, [onboardingChainId]);

    const goToProfile = () => {
        navigate('../profile');
    }


    return (
        <div className='w-full flex-col' style={{ height: '90vh' }}>
            <div className='flex justify-center h-1/12'>
                <div onClick={goToProfile} className={`flex ${ lang == 'ar' && 'flex-row-reverse' } w-11/12 justify-start items-center`}>
                    <Avatar  src='https://bit.ly/broken-link' />
                    {lang == 'en' ? 
                    (<Text className='text-3xl m-2' color="teal.800" fontWeight={500}>Hello, {name}</Text>)
                    :
                    (<Text className='text-3xl m-2' color="teal.800" fontWeight={500}>اهلا، {name}</Text>)
                    }

                </div>
            </div>
            {loading ? LoadingComponent() : (
                <div className='flex flex-col h-full items-center'>
                    {chains?.map(c => <ChainCard chain={c} />)}
                </div>
            )}
        </div>
    );
};

export default ListOfChains;