import Owner from '../Owner'
import Link from '../Link'
import { Box, Card, Image, Text } from '@chakra-ui/react'
import StepCircle from '../../StepCircle';
import Message from '../Message';
import Localization from '../../../Assets/Lang/Localization.json';


const Level = ({ lang, index, isLast, chain, owner, type }) => {
    console.log('Level chain?.ownerships?.length: ', chain?.ownerships?.length);
    console.log('Level index: ', index);

    const getStatus = () => {
        if(index == chain?.ownerships?.length - 1) {
            return chain?.status === 'expired' ? 'lastOwnership': 'active' 
        }
        else
            return 'complete'
    }

    const CardLevel = ({ }) => {
        return (
            <Card
                width="90vw"
                height="9dvh"
                marginBottom={'1dvh'}
                variant="elevated"
                border="1px solid"
                borderColor="gray.200"
                boxShadow="md"
                borderRadius="md"
                display={'flex'}
                flexDirection={lang == 'en' ? 'row' : 'row-reverse'}
            >
                <div className='ml-4 mr-4'>
                    <StepCircle key={index} isLast={isLast} status={getStatus()} content={index + 1} />
                </div>
                <div className='flex w-full h-full' >
                    <div className='flex w-full justify-center items-center'>
                        <div className={`flex flex-col h-full w-5/6 justify-center ${lang == 'en' ? 'items-start' : 'items-end'} ml-2`}>
                            <Text fontSize="medium" color="teal.900" fontWeight="bold">{owner.user.name}</Text>
                            <Message message={owner.message} />
                        </div>
                    </div>
                </div>
                <div style={{
                    // borderColor: 'black', borderWidth: 2
                }} className='flex p-2 pb-3 pt-3 h-full w-3/12 justify-center items-center'>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="teal.800"
                        // border="1px solid"
                        // borderColor="teal.300"
                        // borderRadius="md"
                        w={'100%'}
                        h={'100%'}
                        // p={2}
                        // boxShadow="sm"
                        // padding={'2dvh'}
                        flexDirection={'column'}
                    >
                        <Text fontSize="lg" fontWeight="semibold">{owner.numberOfDays}</Text>
                        <Text fontSize="medium" fontWeight="semibold">{Localization.chain.days[lang]}</Text>

                    </Box>
                </div>
            </Card>
        )
    }

    const GiftCard = ({ index, isLast }) => {
        return (
            <Card
                width="90vw"
                height="9dvh"
                marginBottom="1dvh"
                variant="elevated"
                border="1px solid"
                borderColor="purple.200"
                boxShadow="lg"
                borderRadius="md"
                display="flex"
                flexDirection={lang === 'en' ? 'row' : 'row-reverse'}
                bgGradient="linear(to-r, purple.100, purple.200)"
                // p={4}
                alignItems="center"
                justifyContent="space-between"
            >
                <div className='ml-4 mr-4'>
                    <StepCircle key={index} isLast={isLast} status={'gift'} />
                </div>
                <div className='flex w-full h-full' >
                    <div className='flex w-full justify-center items-center'>
                        <div className={`flex flex-col h-full w-full justify-center ${lang == 'en' ? 'items-start' : 'items-end'} ml-2`}>
                            <Box
                                className={`flex flex-col h-full w-5/6 justify-center ${lang === 'en' ? 'items-start' : 'items-end'}`}
                                textAlign={lang === 'en' ? 'left' : 'right'}
                            >
                                <Text fontSize="xl" fontWeight="bold" color="purple.700">
                                    {Localization.chain.readyToBeGifted[lang]}
                                </Text>
                                {/* <Text fontSize="md" color="purple.600">
                                    {lang === 'en' ? 'Click to pass on the joy.' : 'انقر للإهداء.'}
                                </Text> */}
                            </Box>
                        </div>
                    </div>
                </div>
                <div className='flex h-full w-5/12 justify-center items-center'>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={
                            chain?.TimeToDieInDays > 5 ? "green.800" :
                                chain?.TimeToDieInDays > 2 ? "yellow.800" :
                                    "red.800"
                        }
                        bg={
                            chain?.TimeToDieInDays > 5 ? "green.100" :
                                chain?.TimeToDieInDays > 2 ? "yellow.100" :
                                    "red.100"
                        }
                        border="2px solid"
                        borderColor={
                            chain?.TimeToDieInDays > 5 ? "green.300" :
                                chain?.TimeToDieInDays > 2 ? "yellow.300" :
                                    "red.300"
                        }
                        borderRadius="md"
                        p={2}
                        boxShadow="md"
                        margin="1dvh"
                        width="30dvh"
                        height="6dvh"
                        fontSize="lg"
                        fontWeight="semibold"
                        textAlign="center"
                    >
                        <Box >
                            <Text fontSize="md" fontWeight="semibold">
                                {Localization.chain.giftTimeLeft[lang]}
                            </Text>
                            {lang == 'en' ?
                                <Text fontSize="lg" fontWeight="semibold" dir="rtl">
                                    {chain?.TimeToDieInDays}D
                                </Text> :
                                <Text fontSize="lg" fontWeight="semibold" dir="rtl">
                                    {chain?.TimeToDieInDays} {'ايام '}
                                </Text>
                            }
                        </Box>
                    </Box>
                </div>
            </Card>
        )
    }

    const ExpiredCard = ({ index, isLast }) => {
        console.log("ExpiredCard: ", index, isLast);
        return (
            <Card
                width="90vw"
                height="9dvh"
                marginBottom="1dvh"
                variant="elevated"
                border="1px solid"
                borderColor="red.200"
                boxShadow="lg"
                borderRadius="md"
                display="flex"
                // backgroundColor={'blue'}
                flexDirection={lang === 'en' ? 'row' : 'row-reverse'}
                bgGradient="linear(to-r, red.200, red.300)"
                // p={4}
                alignItems="center"
                justifyContent="space-between"
            >
                <div className='ml-4 mr-4'>
                    <StepCircle key={index} isLast={isLast} status={'expired'} />
                </div>
                <div className='flex w-full h-full' >
                    <div className='flex w-full justify-center items-center'>
                        <div className={`flex flex-col h-full w-full justify-center ${lang == 'en' ? 'items-start' : 'items-end'} ml-2`}>
                            <Box
                                // style={{ backgroundColor: 'green' }}
                                className={`flex flex-col h-full w-full justify-center ${lang === 'en' ? 'items-start' : 'items-end'}`}
                                textAlign={lang === 'en' ? 'left' : 'right'}
                            >
                                <Text fontSize={lang == 'en' ? "l": "xl"} fontWeight="bold" color="red.700">
                                    {lang == 'en' ? `This Bracelet is dead with ${getLastOwnership()?.user?.name} 😢` : `😢 ${getLastOwnership()?.user?.name} هذه الاسوارة ماتت عند `}
                                </Text>
                                {/* <Text fontSize="md" color="purple.600">
                                    {lang === 'en' ? 'Click to pass on the joy.' : 'انقر للإهداء.'}
                                </Text> */}
                            </Box>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

    const getLastOwnership = () => {
        return chain?.ownerships[chain?.ownerships.length - 1];
    }

    const renderCard = () => {
        switch (type) {
            case 'gift':
                return <GiftCard />;
            case 'ownership':
                return <CardLevel />;
            case 'expired':
                return <ExpiredCard />;
            default:
                return null;
        }
    };

    return renderCard()
}

export default Level;