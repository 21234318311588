// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        heading: `'Rubik', sans-serif`,
        body: `'Rubik', sans-serif`,
    },
});

export default theme;
