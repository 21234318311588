import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';

const initialState = {
    token: null,
    id: null,
    lang: 'ar',
    onBoarded: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addToken: (state, action) => {
            state.token = action.payload.access_token;
            state.id = action.payload.id;
        },
        logout: (state) => {
            state.token = null;
            state.id = null;
        },
        setLang: (state) => {
            state.lang = ['ar', 'en'].find(l => l !== state.lang);
        },
        finishedOnBoarded: (state) => {
            state.onBoarded = true;
        } 
    },
    extraReducers: () => {
    }
});

export const { addToken, logout, setLang, finishedOnBoarded } = authSlice.actions;
export default authSlice.reducer;
export const selectToken = (state) => state.auth.token;