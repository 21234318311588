import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';

// Define your initial state here
const initialState = {
  chain: null,
  onboardingChainId: null,
  ListOfChains: null,
  loading: false,
  ownChainLoading: false,
  ownChainError: null,
  error: null,
  listOfChainsError: null,
};

// Define your async thunk for fetching by chainId
export const fetchByChainId = createAsyncThunk(
  'chain/fetchByChainId',
  async (chainId) => {
    console.log("data: ", chainId);
    try {
      // Perform your API request here
      console.log("data: ", chainId);
      const response = (await axiosInstance.get(`/chain/${chainId}`)).data;
      // const data = await response;
      console.log("data: ", response);
      return response;
    } catch (error) {
      // Handle any errors that occur during the API request
      throw new Error('Failed to fetch chain by chainId');
    }
  }
);

export const acceptChain = createAsyncThunk(
  'chain/acceptChain',
  async ({ chainId, message, name }) => {
    console.log("data: ", chainId);
    try {
      // Perform your API request here
      console.log("data: ", chainId);

      const ownership = (await axiosInstance.post(`/chain/claim`, { chainId, message, name })).data;
      // const data = await response;
      console.log("data: ", ownership);
      return ownership;
    } catch (error) {
      // Handle any errors that occur during the API request
      console.log("error: ", error);
      throw new Error(error.response.data.message);
      // throw new Error('Failed to accept chain by chainId');
    }
  }
);

export const getChains = createAsyncThunk(
  'chain/getChains',
  async ({ }, { dispatch }) => {
    console.log('getChains');
    try {
      // Perform your API request here
      // let client = axiosInstance();
      const chains = (await axiosInstance.get(`/chain/user`)).data;
      // const data = await response;
      console.log("data: ", chains);
      return chains;
    } catch (error) {
      if (error.response.status === 401) {
        console.log('logging out');
        // dispatch(logout());
      }
      console.log("error: ", error);
      // Handle any errors that occur during the API request
      throw new Error('Failed to accept chain by chainId');
    }
  }
);


// Create a slice for the chain state
const chainSlice = createSlice({
  name: 'chains',
  initialState,
  reducers: {
    chainOnboarding: (state, action) => {
      state.onboardingChainId = action.payload.id;
      
    },
    finishOnboardingChain: (state) => {
      state.onboardingChainId = null;
    },
    clearErrors: (state) => {
      state.ownChainError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByChainId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchByChainId.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload);
        state.loading = false;
        state.chain = action.payload;
      })
      .addCase(fetchByChainId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(acceptChain.pending, (state) => {
        state.ownChainLoading = true;
        state.ownChainError = null;
      })
      .addCase(acceptChain.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload);
        state.ownChainLoading = false;
        if (state.chain) {
          state.chain.owners.push({...action.payload, user: {name: 'Majed'}});
        }
      })
      .addCase(acceptChain.rejected, (state, action) => {
        state.ownChainLoading = false;
        state.ownChainError = action.error.message;
      })
      .addCase(getChains.pending, (state) => {
        state.loading = true;
        state.listOfChainsError = null;
      })
      .addCase(getChains.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload);
        state.loading = false;
        state.listOfChains = action.payload;
      })
      .addCase(getChains.rejected, (state, action) => {
        state.loading = false;
        state.listOfChainsError = action.error.message;
      });
  },
});

export const { reducer } = chainSlice;
export const { chainOnboarding, finishOnboardingChain, clearErrors } = chainSlice.actions
export default reducer;