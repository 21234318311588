import React, { useEffect, useState } from 'react';
import { Button, FormControl, Input, Link, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Localization from '../../Assets/Lang/Localization.json';
import { clearRegisterError, registerAsync } from '../../Slicers/registerSlice';


const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    let token = useSelector((state) => state.auth.token);
    let lang = useSelector((state) => state.auth.lang);
    let registerError = useSelector((state) => state.register.registerError);
    let registerLoading = useSelector((state) => state.register.loading);



    useEffect(() => {
        if (token) {
            navigate('/chains');
            console.log("Token: ", token);
        }
    }, [token]);

    useEffect(() => {
        if (registerError) {
            toast({
                title: "Register Error",
                description: registerError,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            dispatch(clearRegisterError())
        }
    }, [registerError])




    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);

        dispatch(registerAsync({ email, name, password }));


    };

    return (
        <form style={{ height: '100dvh' }} className="flex justify-center flex-col h-full login-form p-5" onSubmit={handleSubmit}>
            <div className='flex justify-center mb-20'>
                <Text className='' fontSize={'xxx-large'}>{Localization.auth.register[lang]}</Text>

            </div>
            <FormControl className='mb-5' >
                <Input dir={lang == 'ar' && 'rtl'} placeholder={Localization.auth.email[lang]} value={email} onChange={handleEmailChange} />
            </FormControl>
            <FormControl className='mb-5'>
                <Input dir={lang == 'ar' && 'rtl'} placeholder={Localization.auth.name[lang]} value={name} onChange={handleNameChange} />
            </FormControl>
            <FormControl>
                <Input dir={lang == 'ar' && 'rtl'} placeholder={Localization.auth.password[lang]} type="password" value={password} onChange={handlePasswordChange} />
            </FormControl>

            <div className="flex flex-col self-center justify-center mt-10 w-full">
                <div className='flex flex-row mt-2'>
                    <Button
                        isLoading={registerLoading}
                        type="submit"
                        loadingText={Localization.auth.loading[lang]}
                        colorScheme='teal'
                        variant='solid'
                        zIndex={20}
                        className='w-full'
                        marginRight={2}
                    >
                        {Localization.auth.register[lang]}
                    </Button>
                </div>
                <Text className='flex mt-4 justify-center'>
                    <Link color='teal.500' onClick={() => navigate('/login')}>
                        {Localization.auth.login[lang]}
                    </Link>
                </Text>

            </div>
        </form>
    );
};

export default Register;