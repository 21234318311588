import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web



import chainsSlice from './Slicers/chainSlice';
import authSlice from './Slicers/authSlice';
import loginSlice from './Slicers/loginSlice';
import registerSlice from './Slicers/registerSlice';
import profileSlice from './Slicers/profileSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice);



export const store = configureStore({
  reducer: {
    chain: chainsSlice,
    auth: persistedAuthReducer,
    login: loginSlice,
    register: registerSlice,
    profile: profileSlice,
  },
});

export const persistor = persistStore(store);