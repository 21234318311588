import React, { useEffect } from 'react';
import { Box, Heading, Text, Stack, Button, Icon, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setLang } from '../Slicers/authSlice';
import { GrLanguage } from "react-icons/gr";
import Localization from '../Assets/Lang/Localization.json';
import { getProfileAsync } from '../Slicers/profileSlice';


const Profile = () => {
  const dispatch = useDispatch();

  let lang = useSelector((state) => state.auth.lang);
  let profile = useSelector((state) => state.profile.profile);
  // let profile = {
  //   email: 'Majed@whatever.com',
  //   name: 'John Doe',
  //   mobileNumber: '1234567890',
  // }

  useEffect(() => {
    console.log('Profile useEffect');
    dispatch(getProfileAsync());
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  }

  const changeLang = () => {
    dispatch(setLang());
  }

  return (
    <Box
      height="90vh"
      display="flex"
      flexDirection="column"
      bg="gray.50"
      borderRadius="lg"
      boxShadow="lg"
      p={6}
      m={4}
      dir={lang === 'en' ? 'ltr' : 'rtl'} // Set the text direction based on the language
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="1/6"
        textAlign={lang === 'en' ? 'left' : 'right'} // Align text based on the language
      >
        <Text fontSize="4xl" fontWeight="bold" color="teal.700">
          {Localization.profile.profile[lang]}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        h="4/6"
        p={6}
        bg="white"
        borderRadius="lg"
        boxShadow="md"
        textAlign={lang === 'en' ? 'left' : 'right'} // Align text based on the language
      >
        <Stack spacing={5}>
          <Box>
            <Text fontWeight="bold" color="gray.600">{Localization.profile.email[lang]}</Text>
            <Text color="gray.800">{profile.email}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" color="gray.600" >{Localization.profile.name[lang]}</Text>
            <Text color="gray.800">{profile.name}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" color="gray.600">{Localization.profile.phone[lang]}</Text>
            <Text color="gray.800">{profile.mobileNumber}</Text>
          </Box>
          <Box display="flex" alignItems="center">
            <Icon
              onClick={changeLang}
              as={GrLanguage}
              boxSize={6}
              color="teal.500"
              cursor="pointer"
              _hover={{ color: 'teal.700' }}
              marginRight={lang === 'en' ? 2 : 0}
              marginLeft={lang !== 'en' ? 2 : 0}
            />
            <Text
              color="teal.500"
              fontWeight="medium"
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }}
              onClick={changeLang}
              marginLeft={lang === 'en' ? 2 : 0}
              marginRight={lang !== 'en' ? 2 : 0}
            >
              Change Language
            </Text>
          </Box>
        </Stack>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        h="1/6"
        p={4}
      >
        <Button
          colorScheme="red"
          onClick={handleLogout}
          size="lg"
          width="100%"
          maxW="sm"
          boxShadow="sm"
          _hover={{ boxShadow: 'md' }}
        >
          {Localization.profile.logout[lang]}
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;
