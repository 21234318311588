import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { addToken } from './authSlice';

const initialState = {
    registerError: null,
    id: null,
    loading: false,
};

const registerAsync = createAsyncThunk(
    'register/registerAsync',
    async (credentials, { dispatch }) => {
        try {
            const response = await axiosInstance.post(`/auth/register`, {
                email: credentials.email,
                name: credentials.name,
                password: credentials.password,
            });

            dispatch(addToken(response.data));

            return response.data;
        }
        catch (error) {
            console.log('Login failed', error);
            throw new Error(error.response.data.message);
        }
    });

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        clearRegisterError: (state) => {
            state.registerError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerAsync.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(registerAsync.rejected, (state, action) => {
                console.log('action', action);
                state.registerError = action.error.message;
                state.loading = false;
            }).addCase(registerAsync.pending, (state) => {
                state.loading = true;
                state.registerError = null;
            });
    }
});

export const { clearRegisterError } = registerSlice.actions;
export { registerAsync };
export default registerSlice.reducer;