import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';

const initialState = {
    profile: {
        email: null,
        name: null,
    },
    loading: false,
    error: null,
};

const getProfileAsync = createAsyncThunk(
    'prfile/fetch',
    async () => {
        try {
            const response = await axiosInstance.get(`/auth/profile`);

            // dispatch(addToken(response.data));

            return response.data;
        }
        catch (error) {
            console.log('Profile failed', error);
            throw new Error(error.response.data.message);
        }
    });

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        clearProfileError: (state) => {
            state.Error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileAsync.fulfilled, (state, action) => {
                state.profile.email = action.payload.email;
                state.profile.name = action.payload.name;
                state.loading = false;
            })
            .addCase(getProfileAsync.rejected, (state, action) => {
                console.log('action', action);
                state.error = action.error.message;
                state.loading = false;
            }).addCase(getProfileAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            });
    }
});

export const { clearProfileError } = profileSlice.actions;
export { getProfileAsync };
export default profileSlice.reducer;