import React, { useState } from 'react';
import { Flex, Box, Button, Icon, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LiaRingSolid } from "react-icons/lia";
import { MdOutlineLeaderboard } from "react-icons/md";
import { useSelector } from 'react-redux';

const BottomMenu = () => {
    const [activeButton, setActiveButton] = useState('');

    let lang = useSelector((state) => state.auth.lang);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    return (
        <Flex
            as="nav"
            position="fixed"
            bottom="0"
            width="100%"
            bg="white"
            borderTop="1px solid #e2e8f0"
            justifyContent="space-around"
            padding="0.5rem"
            height={'10dvh'}
            flexDir={lang == 'en' ? 'row' : 'row-reverse'}
        >
            <Box>
                <Button
                    as={Link}
                    to="/chains"
                    variant="ghost"
                    height={'100%'}
                    // bgColor={activeButton === 'chains' && '#eaeaea'}
                    onClick={() => handleButtonClick('chains')}
                    className='flex flex-col justify-center items-center '
                >
                    <Text color="teal.700">
                        {lang == 'en' ? 'Bracelets' : 'الاساور'}
                    </Text>
                    <Icon color="teal.700" className='mt-0.5' as={LiaRingSolid} boxSize={6} marginRight={2} />
                </Button>
            </Box>
            <Box>
                <Button
                    as={Link}
                    to="/leaderboard"
                    variant="ghost"
                    height={'100%'}
                    // bgColor={activeButton === 'chains' && '#eaeaea'}
                    onClick={() => handleButtonClick('chains')}
                    className='flex flex-col justify-center items-center '
                >
                    <Text color="teal.700">
                        {lang == 'en' ? 'Leader Board' : 'الترتيب'}
                    </Text>
                    <Icon color="teal.700" className='mt-0.5' as={MdOutlineLeaderboard} boxSize={6} marginRight={2} />
                </Button>
            </Box>
        </Flex>
    );
};

export default BottomMenu;

