import { Text } from '@chakra-ui/react';
import React from 'react';

const LeaderBoard = () => {
    return (
        <div style={{height:'90vh'}} className='flex justify-center items-center'>
            <Text className='' fontSize={'xx-large'}>
                Coming Soon!
            </Text>
        </div>
    );
};

export default LeaderBoard;