import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Image, Text, ModalFooter, Button, Icon} from "@chakra-ui/react";
import Localization from '../../../Assets/Lang/Localization.json';

// Icons
import { GrLanguage } from "react-icons/gr";

// Images
import coloredBand from '../../../Assets/Images/colored-band.png'
import coloredManyHands from '../../../Assets/Images/colored-many-hands.png'
import coloredBandGiving from '../../../Assets/Images/colored-band-giving.png'
import coloredDeadBank from '../../../Assets/Images/dead-band-colored.png'
import { setLang } from "../../../Slicers/authSlice";
import { useDispatch } from "react-redux";



const OnBoardingPopup = (lang, isOnboardingPopupOpen, closeOnboardingPopup, ownChainLoading) => {
    
    const dispatch = useDispatch();
    
    const changeLang = () => {
        dispatch(setLang());
    }

    return (
        // <div className="flex justify-center items-center min-h-screen h-screen"> {/* Ensures the parent container takes at least the full viewport height */}
        <Modal isOpen={isOnboardingPopupOpen} onClose={closeOnboardingPopup} size={"sm"}>
            <ModalOverlay />
            <ModalContent className="" >
                <ModalHeader className='self-center'>{Localization.chain.onBoarding.title[lang]}</ModalHeader>
                <ModalCloseButton />
                <ModalBody className='w-full  ' p={2}>
                    
                    <Icon className='absolute m-2 left-0 top-0' color="teal.500" onClick={changeLang} as={GrLanguage} boxSize={6} marginRight={2} />
                    <div className='flex flex-col items-center overflow-scroll h-60dvh' >

                        <Box className='flex flex-col h-1/5dvh items-center' >
                        <Image className="h-1/2" src={coloredBand} />
                        <Text style={{direction:'rtl'}} noOfLines={20}>
                                {Localization.chain.onBoarding.nameBracelet[lang]}
                            </Text>
                        </Box>
                        <Box className='flex flex-col  h-1/5dvh items-center' >
                            {/* <Image className="h-1/2" src={} /> */}
                            <Text style={{direction:'rtl'}} noOfLines={20}>
                                {Localization.chain.onBoarding.leaveMessage[lang]}
                            </Text>
                        </Box>
                        <Box className='flex flex-col  h-1/5dvh items-center' >
                            <Image className="h-1/2" src={coloredBandGiving} />
                            <Text style={{direction:'rtl'}} noOfLines={20}>
                                {Localization.chain.onBoarding.giftBracelet[lang]}
                            </Text>
                        </Box>
                        <Box className='flex flex-col  h-1/5dvh items-center' >
                            <Image className="h-1/2" src={coloredManyHands} />
                            <Text style={{direction:'rtl'}} noOfLines={20}>
                                {Localization.chain.onBoarding.trackBracelet[lang]}
                            </Text>
                        </Box>
                        <Box className='flex flex-col  h-1/5dvh items-center' >
                            <Image className="h-1/2" src={coloredDeadBank} />
                            <Text style={{direction:'rtl'}} noOfLines={20}>
                                {Localization.chain.onBoarding.deadBracelet[lang]}
                            </Text>
                        </Box>

                    </div>


                </ModalBody>

                <ModalFooter>
                    <Button
                        isLoading={ownChainLoading}
                        loadingText='Submitting'
                        colorScheme='teal'
                        variant='outline'

                        className='flex h-1/6 w-full self-center mt-2'

                        onClick={closeOnboardingPopup}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        // </div>
    );
}

export default OnBoardingPopup;