import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const token = useSelector((state) => state.auth.token);

  
  
  if (!token) {
    return <Navigate to="/login" />;
  }
  
  console.log('AuthGuard, token: ', token);

  return children;
};

export default AuthGuard;
