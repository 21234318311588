import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthGuard from '../AuthGuard';
import BottomMenu from '../../Menu/BottomMenu';

const ProtectedLayout = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <AuthGuard>
      <Outlet />
      {token && <BottomMenu />}
    </AuthGuard>
  );
};

export default ProtectedLayout;
