import { Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react'
import React from 'react';

const Message = ({ message }) => {
    const [isPopoverOpen, setPopoverOpen] = React.useState(false);

    const handleOpenPopover = () => {
        console.log('pop over open');
        setPopoverOpen(true);
        console.log('isPopoverOpen', isPopoverOpen);
      };
    
      const handleClosePopover = () => {
        console.log('pop over close');
        setPopoverOpen(false);
      };
      
    return (<div>
        <Popover isOpen={isPopoverOpen} onClose={handleClosePopover} placement="bottom-start">
            <PopoverTrigger>
                <Text
                    onClick={handleOpenPopover}
                    cursor="pointer"
                    noOfLines={1}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="medium"
                    fontStyle={'unset'}
                    maxW="50vw"
                    color="teal.900"
                >
                    {message}
                </Text>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                {message}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    </div>
    )
}

export default Message;