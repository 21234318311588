import React from 'react';
import { Spinner } from '@chakra-ui/react';

const LoadingComponent = () => {
    return (
        <div className='flex h-full w-full justify-center items-center'>
            <Spinner size='xl' color='red.500' />
        </div>
    );
}

export default LoadingComponent;