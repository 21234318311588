import { Box, Icon, Text } from "@chakra-ui/react";
import { GiBreakingChain } from "react-icons/gi";
import { IoMdCheckmark } from "react-icons/io";



const StepCircle = ({ status, content, isLast }) => {
    let borderColor;
    let bgColor;
    let icon;

    console.log('StepCircle status: ', status);

    switch (status) {
        case 'complete':
            borderColor = 'teal.500';
            bgColor = 'teal.500';
            icon = <Icon as={IoMdCheckmark} boxSize={6} />;
            break;
        case 'active':
            borderColor = 'teal.500';
            bgColor = 'white';
            break;
        case 'lastOwnership':
            borderColor = "red.500"
            bgColor = 'white';
            icon = <Icon as={GiBreakingChain} color="red.600" boxSize={6} />;
            break;
        case 'gift':
            bgColor = "purple.800"
            borderColor = "purple.400"
            icon = '🎁';
            break;
        case 'expired':
            bgColor = "red.800"
            borderColor = "red.500"
            icon = '❌';
            break;

        default:
            borderColor = 'gray.300';
            bgColor = 'white';
    }
    const renderComponent = () => {
        console.log('status', status);
        switch (status) {
            case 'active':
                return <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="2px solid"
                    borderColor={borderColor}
                    bg={bgColor}
                    borderRadius="full"
                    width="4.5dvh"
                    height="4.5dvh"
                    p={2}
                    boxShadow="md"
                    // p={2}
                    color={status === 'complete' ? 'white' : 'black'}
                    fontSize="lg"
                    marginTop={'2dvh'}
                >

                    {icon}
                    {status == 'active' && <Text>{content}</Text>}

                    {isLast && <Box backgroundColor={'teal'} style={{ width: 3, height: '4dvh', bottom: '-2.5dvh', zIndex: 10, position: 'absolute' }} />}

                </Box>
            case 'lastOwnership':
                return <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="2px solid"
                    borderColor={borderColor}
                    bg={bgColor}
                    borderRadius="full"
                    width="4.5dvh"
                    height="4.5dvh"
                    p={2}
                    boxShadow="md"
                    // p={2}
                    color={status === 'complete' ? 'white' : 'black'}
                    fontSize="lg"
                    marginTop={'2dvh'}
                >

                    {icon}
                    {/* {status == 'active' && <Text>{content}</Text>} */}

                    {isLast && <Box backgroundColor={'teal'} style={{ width: 3, height: '4dvh', bottom: '-2.5dvh', zIndex: 10, position: 'absolute' }} />}

                </Box>
            case 'complete':
                return <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="2px solid"
                    borderColor={borderColor}
                    bg={bgColor}
                    borderRadius="full"
                    width="4.5dvh"
                    height="4.5dvh"
                    p={2}
                    boxShadow="md"
                    // p={2}
                    color={status === 'complete' ? 'white' : 'black'}
                    fontSize="lg"
                    marginTop={'2dvh'}
                >

                    {icon}
                    {status == 'active' && <Text>{content}</Text>}

                    {isLast && <Box backgroundColor={'teal'} style={{ width: 3, height: '4dvh', bottom: '-2.5dvh', zIndex: 10, position: 'absolute' }} />}

                </Box>
            case 'gift':
                return <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="purple.800"
                    border="2px solid"
                    borderColor="purple.400"
                    borderRadius="full"
                    p={2}
                    boxShadow="md"
                    w="5dvh"
                    h="5dvh"
                    fontSize="2xl"
                    fontWeight="bold"
                    textAlign="center"
                >
                    {icon}
                </Box>
            case 'expired':
                return <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    // color="purple.800"
                    // border="2px solid"
                    // borderColor={borderColor}
                    // borderRadius="full"
                    p={2}
                    // boxShadow="md"
                    w="2dvh"
                    h="2dvh"
                    // fontSize="2xl"
                    // fontWeight="bold"
                    textAlign="center"
                >
                    {/* <Icon
                        // position={'absolute'}
                        color="red.600"
                        as={GiBreakingChain}
                        boxSize={7}

                        // ml={lang === 'en' ? 'auto' : 'unset'}
                        // mr={lang === 'ar' ? 'auto' : 'unset'}
                    /> */}
                </Box>
        }
    }
    return renderComponent();
};


export default StepCircle;